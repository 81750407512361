import logo from './201.png';
import ladyone from './lady_1.PNG';
import ladytwo from './lady_2.PNG';
import ladythree from './lady_3.PNG';
import ladyfour from './lady_4.PNG';

//const lady1 = ladyone; 
const lady2 = ladytwo; //set to const test to try to fix phantom image not loading issue 
const lady3 = ladythree; 
const lady4 = ladyfour; 

const About = () => {
    return ( 
        
        <div id="About"> 
            <div id="border"></div>
            <h2 id="aboutheader"> - L A D Y - <br /><br />Last Available Damsel Yours</h2>
            <h2 id="ladydescription"> codename - s8yx33udPR </h2>
            <p id="ladydescription"> 
                Origin - LADY was born of an accident in a data <br />  generation script.  Her 10,000 fragments now wander our <br /> datastreams alone, searching for a reason to exist.
            </p> 
           <br />
           <br />
           <br />
           <br />
           <div id="rarity">
           <h2> --- R A R I T Y ---<br />-- T Y P E S --</h2> 
           <br />
           <h3> 
                [ 0-200 ]
           </h3> 
           <br />
           <img src={lady4} className="App-logo" alt="lady nft picture white background - please refresh page if not visible" />
           <br />
           <h3> 
                [ 201-400 ]
           </h3>            
           <br />
           <img src={lady2} className="App-logo" alt="lady nft picture black background - please refresh page if not visible" />
           <br />
           <h3> 
                [ 401-10000 ]
           </h3>            
           <br />
           <img src={lady3} className="App-logo" alt="lady nft picture colored background - please refresh page if not visible" />
           </div>
         
           <br />

              <h2> Resources </h2> 
                <a href="https://polygonscan.com/" id="statbutton" className="navlinkexternal" style= {{
                    }}>   How to connect Metamask to Polygon 'Matic Mainnet' - Medium Article </a> 
                <br /> <br /><br /> <br />                    
                <a href="https://wallet.polygon.technology/bridge" id="statbutton" className="navlinkexternal" style= {{
                    }}>  Ethereum 'Mainnet' to 'Matic' Polygon Bridge </a> 
                <br /> <br /><br /> <br />
                <a href="https://medium.com/stakin/bridging-to-matic-network-from-ethereum-chain-a1b59a64a7ce" id="statbutton" className="navlinkexternal" style= {{
                    }}>Bridging to Matic Network from Ethereum chain - Medium Article </a> 
                <br /> <br /><br /> <br />

                <h2> Community </h2>
                

                
                <a href="https://discord.gg/dYApub3mMf" id="statbutton" className="navlinkexternal" style= {{
                }}>  Join the Discord Community!  </a>
                
                <br /> <br /><br /> <br />
                <a href="https://twitter.com/0xdivi?ref_src=twsrc%5Etfw" id="statbutton" className="navlinkexternal" >@0xdivi on twitter </a>
{/*
                <a className="navlinkexternal" href="https://twitter.com/0xdivi?ref_src=twsrc%5Etfw" class="twitter-follow-button" data-show-count="false">follow @0xdivi</a><script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
                 */}
                <br /><br />  <br /><br />
                For all inquiries contact @0xdivi on twitter. 
                <br /><br /><br />

                <p id="disclaimer"> Disclaimer: <br /> 
                This project involves cryptocurrency, and is connected to a contract deployed on the Polygon blockchain. <br />
            Interaction with the functions in this project involves real 'fake internet magic money', <br /> e.g. CRYPTOCURRENCY, tied to real money.  <br /><br />
                The creator assumes no responsibility for any at all losses or damages <br /> 
                 caused by the use of this unaudited experimental smart contract. <br /> 
                 The creator is not a financial advisaurus. <br />
                 This work is considered final as is. </p> 






            <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br />
        </div>
     );
}
 
export default About;