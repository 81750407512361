import logo from './201.png';
import { useEffect, useState } from "react";
import {
  connectWallet,
  getCurrentWalletConnected,
  mint,
  setGameStatusContract
} from "./functions.js"; 
//import importWalletButton from "./Importwalletbutton.js"
import About from "./About.js"

///////////////////////////////////////START CONNECT WALLET [do not change]/////////////////////////////////////
const Minter = (props) => {
  const [walletAddress, setWallet] = useState("");
  const [status, setStatus] = useState("");
  const [info, setInfo] = useState(""); 
  useEffect(async () => {
    const { address, status } = await getCurrentWalletConnected();

    setWallet(address);
    setStatus(status);

    addWalletListener();
  }, []);

  function addWalletListener() {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", (accounts) => {
        if (accounts.length > 0) {
          setWallet(accounts[0]);
          setStatus("Ready.");
        } else {
          setWallet("");
          setStatus("🦊 Connect to Metamask using the top right button.");
        }
      });
    } else {
      setStatus(
        <p>
          {" "}
          🦊{" "}
          <a target="_blank" href={`https://metamask.io/download.html`}>
            You must install Metamask, a virtual Ethereum wallet, in your
            browser.
          </a>
        </p>
      );
    }
  }

  const connectWalletPressed = async () => {
    const walletResponse = await connectWallet();
    setStatus(walletResponse.status);
    setWallet(walletResponse.address);
  };
///////////////////////////////////////END CONNECT WALLET/////////////////////////////////////

//START 

  //const [fireStatus, setFire] = useState("");
  //const [fireGameStatus, fireSetGameStatus] = useState("");

  const fireme = async () => {                           
    setGameStatusContract();
    //console.log("Ask dev to reenable button")
  };
  const link = "https://opensea.io/collection/lady-s8yx33udpr";
  const linkcontract = "https://polygonscan.com/address/0x80972f39da0f72dc353fd15238af81be663f9a96";
  const explorer = "https://explorer.matic.network/address/0x2af3aa8f9520b99011a4255da96f9eb2f60bf47d"; //redirects straight to polygonscan? 
  return (
    //
    <div className="Minter">
      <button id="walletButton" onClick={connectWalletPressed}>
        {walletAddress.length > 0 ? (
          "Connected: " +
          String(walletAddress).substring(0, 6) +
          "..." +
          String(walletAddress).substring(35, 38)
        ) : (
          <span>Connect Wallet</span>
        )}
      </button>
        
      <br></br>
      <h1 id="title"> L A D Y </h1>
      <img src={logo} className="App-logo" alt="logo" id="supermint"/>
      <br /> 
      <p> STATUS: PAUSED </p> 
      <button id="buttonspecialgreyedout" > M I N T </button> 
      {/*<button id="buttonspecial" onClick={fireme}> M I N T </button>  */}

      <p id="status" > 
      <br />
      <a id="link" href={link}> View Collection on Opensea</a> <br /> <br /> 
      <a id="link" href={linkcontract}> View Contract on Polygonscan</a>
      <br /><br />
      <br /><br />
      
      <span> make sure you are connected to Polygon Matic Mainnet or your funds will be permanently lost </span> <br /> 

        <br /> 
      </p>
      <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> 
      <About />
    </div>
  );
};

export default Minter;
